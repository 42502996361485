<template>
  <section class="xa-container xa-container--hasFoot">
    <template v-if="!isLoading">
      <van-cell
        title="确认报修设备"
        :value="deviceInfo"
        value-class="xa-txt-nowrap"
        is-link
        @click="showInputPopup = true"
      ></van-cell>
      <div class="xa-cell__box">检测信息</div>
      <div class="xa-cell__box xa-bg-white">
        <textarea
          v-model="description"
          rows="5"
          placeholder="请输入"
        ></textarea>
      </div>
      <div class="xa-cell__box">检测照片</div>
      <div class="xa-cell__box xa-bg-white">
        <van-uploader :after-read="afterRead" v-model="images" multiple/>
      </div>
      <van-cell
        class="xa-mgt-8"
        title="定损物料"
        is-link
        :value="lossMaterialInfo"
        @click="gotoEnsureLossMaterial"
      ></van-cell>
      <div class="xa-cell__box">请您根据故障情况，确认是否申请定责</div>
      <van-radio-group v-model="event_type">
        <van-cell-group>
          <van-cell
            v-for="item in event_type_options"
            :title="item.label"
            clickable
            @click="event_type = item.value"
            :key="item.value"
          >
            <van-radio slot="right-icon" :name="item.value" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <EnsureFootBtn :disabled="submitBtnDisabled" @submit="onSubmit" />
      <van-popup v-model="showInputPopup" position="top">
        <div>
          <div class="xa-cell__box">
            <div class="xa-txt-blue xa-mgb-8">录入序列号</div>
            <div>当前设备信息：{{ deviceInfo }}</div>
          </div>
          <van-field v-model="inputSn" placeholder="请输入" clearable>
            <template #button>
              <div class="xa-cell">
                <van-icon
                  name="scan"
                  class="xa-mgr-16 xa-txt-20"
                  @click="scanQrCode"
                />
                <van-button
                  :disabled="!inputSn"
                  size="small"
                  type="info"
                  @click="onCheckSn"
                  >确定</van-button
                >
              </div>
            </template>
          </van-field>
        </div>
      </van-popup>
    </template>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import uploadMix from '@/mixins/upload'
import { Uploader, RadioGroup, Radio, Icon } from 'vant'
import appController, { isApp } from '@/controllers/app-android'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import {
  checkCanAuthAs,
  commitForAs,
  getRepairEquipmentInfo,
  ensureSn,
  getDiagnosisDetail
} from '@/apis/authas'
import { getLossMaterial } from '@/apis/material'
import resource from '@/controllers/resource'
/**
 * 用于临时存储数据，
 * 在跳转到定损物料页面时候，临时存储下已经填入的表单信息
 * 可以用sessionStorage实现，只是在app中不能刷新页面，所以简单实现
 */
const pageStorage = {}

function getPageStorage(key) {
  const value = pageStorage[key]
  pageStorage[key] = null
  return value
}

function setPageStorage(key, value) {
  pageStorage[key] = value
}

// function test(params) {
//   window.console.log('params', params)
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve({
//         device: {
//           sn: '588962247',
//           serial_number: '588962247',
//           name: 'B12710',
//           code: '09-017-00011',
//           model: 'BAT1',
//           eq_model: 'B12710',
//           pic:
//             'http://agri-private.static.xag.cn/v3/upload/2019/01/28/bfcc77c0413f680a06b3f8e4526c37ac.jpg?imageView2/0/w/600/h/600&e=1866027861&token=kL3qRYaFy4Ip8uZVbJyYE1KL6GgwtdNbqwu5C4lO:p0KViIeBhl1gQifrZfUZiJMDdRQ='
//         },
//         lossMaterialInfo: '3类23件'
//       })
//     }, 3000)
//   })
// }

export default {
  mixins: [basePage, uploadMix],
  components: {
    EnsureFootBtn,
    VanUploader: Uploader,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanIcon: Icon
  },
  config: {
    title: '诊断'
  },
  data() {
    return {
      isApp,
      isLoading: true,
      showInputPopup: false,
      inputSn: '',
      description: '',
      images: [
        // {
        //   url: 'https://img.yzcdn.cn/vant/leaf.jpg',
        //   status: 200,
        //   message: '上传成功'
        // }
      ],
      device: null,
      checkDevice: null,
      lossMaterialInfo: '请录入坏件',
      event_type: 0, // 是否申请定责
      event_type_options: [
        {
          label: '否，客户同意付费维修',
          value: 'no_fix_duty'
        },
        {
          label: '申请定责，初步判断为质量问题',
          value: 'fix_duty'
        }
      ]
    }
  },
  computed: {
    deviceInfo() {
      return `${this.device.eq_model} | ${this.device.serial_number}`
    },
    submitBtnDisabled() {
      return false
    }
  },
  methods: {
    canContinueCheckUpload() {
      if (this.images.find(item => item.status !== 200)) {
        this.$dialog.alert({
          message: '有图片还在上传中，请等待上传完再操作！'
        })
        return false
      }
      return true
    },
    // 前往选择坏件页面
    gotoEnsureLossMaterial() {
      if (this.canContinueCheckUpload() === false) return
      setPageStorage(JSON.stringify(this.$route.query), {
        description: this.description,
        event_type: this.event_type,
        images: Array.from(this.images)
      })
      this.$router.push({
        path: '/ensure-loss-material',
        query: {
          params: JSON.stringify(this.$route.query),
          needBackWhenSubmit: '1',
          needSelectSn: '0'
        }
      })
    },
    onCheckSn() {
      if (this.inputSn) this.toCheckSn(this.inputSn)
    },
    async scanQrCode() {
      this.inputSn = await appController.toScanQRCode()
    },
    async toCheckSn(sn) {
      const result = await this.$_syncSubmitData(
        checkCanAuthAs({
          serial_number: sn
        })
      )
      if (result.serial_number !== this.device.serial_number) {
        this.$dialog
          .confirm({
            title: '标题',
            message: `当前录入序列号:${result.serial_number}\n与已录入的序列号:${this.device.serial_number}不符，\n是否替换？`
          })
          .then(() =>
            this.toUpdateDevice(result).then(
              () => (this.showInputPopup = false)
            )
          )
      } else {
        this.$dialog.alert({
          message: '当前录入的序列号与已录入的序列号一致！'
        })
      }
    },
    async toUpdateDevice(newDevice) {
      await this.$_syncSubmitData(
        ensureSn({
          ...this.$route.query,
          origin_sn: this.device.serial_number,
          ensure_sn: newDevice.serial_number
        }),
        {
          notify: true
        }
      )
      const result = await this.$_fetchDataWidthUi(
        getRepairEquipmentInfo({
          ...this.$route.query
        })
      )
      this.device = result
    },
    checkSubmit(params) {
      let message = ''
      if (params.description.length === 0) {
        message = '请填写检测信息'
      } else if (params.event_type === 0) {
        message = '请确定是否申请定责'
      } else if (params.images.length === 0) {
        message = '请录入检测照片'
      }
      if (message) {
        this.$notify({ type: 'danger', message })
        return false
      }
      return true
    },
    async submitData(params) {
      await this.$_request(commitForAs(params))
      setTimeout(() => {
        this.$router.go(-1)
      }, 300)
    },
    async onSubmit() {
      if (this.canContinueCheckUpload() === false) return
      const params = {
        ...this.$route.query,
        description: this.description,
        event_type: this.event_type,
        images: this.images.map(item => item.guid)
      }
      window.console.warn('onSubmit', params)
      if (this.checkSubmit(params)) {
        await this.$_submitDataWidthUi(
          () => this.submitData(params),
          `经极飞确认的质量问题，客户可获免费维修服务。网点不得收取物料费、工时费、物流费。
网点相关费用支出，后续可在CRM系统提现。`,
          `定责说明`,
          true
        )
      }
    },
    async afterRead(file) {
      const result = await this.uploadMix_afterRead(file)
      if (result === -1) {
        this.images.pop()
      }
    },
    async getLossMaterialsInfo() {
      const { materials } = await this.$_request(
        getLossMaterial(this.$route.query)
      )
      if (materials.length) {
        const total = materials.reduce(
          (total, material) => total + material.count,
          0
        )
        this.lossMaterialInfo = `${materials.length}类${total}件`
      } else {
        this.lossMaterialInfo = '请录入坏件'
      }
    },
    // 获取页面基本信息
    async getBaseInfo() {
      const result = await this.$_request(
        getRepairEquipmentInfo({
          ...this.$route.query
        })
      )
      this.device = result
      await this.getLossMaterialsInfo()
      await this.fetchLastSubmitDetail()
    },
    // 如果曾经提交过，需要把之前的加载出来
    async fetchLastSubmitDetail() {
      try {
        const result = await getDiagnosisDetail({
          ...this.$route.query
        })
        this.description = result.description || ''
        if (Array.isArray(result.images) && result.images.length) {
          const list = await resource.postQiniuKey2Path(result.images)
          this.images = list.map(item => {
            return {
              url: item.thumb_url, // 展示的图片
              guid: item.uid, // 后台需要的guid
              status: 200, // 状态
              message: '上传成功'
            }
          })
        }
      } catch (error) {
        window.console.log(error)
      }
    },
    async fetchData() {
      await this.$_fetchDataWidthUi(this.getBaseInfo())
      const pageStorage = getPageStorage(JSON.stringify(this.$route.query))
      if (pageStorage) {
        this.description = pageStorage.description
        this.images = pageStorage.images
        this.event_type = pageStorage.event_type
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>
<style lang="scss"></style>
