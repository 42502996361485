<template>
  <section class="xa-container xa-container--hasFoot">
    <div class="xa-cell__box xa-bg-warning" style="color:#ffffff" v-if="notice">{{ notice }}</div>
    <div class="xa-bg-white xa-mgb-8">
      <div
        class="xa-line__top"
        v-for="(item, index) in materialList"
        :key="index"
      >
        <div class="material-info xa-cell__box xa-cell">
          <img
            :src="
              item.logo_url ||
                'http://agri-pub.static.xag.cn/image/xag/logo/logo_en.png'
            "
          />
          <div>
            <div>{{ item.name }}</div>
            <div class="xa-txt-12">SN:{{ item.serial_number }}</div>
          </div>
        </div>
        <div
          class="material-info-item"
          v-for="(mItem, mIndex) in item.materials"
          :key="mIndex"
        >
          <div>{{ mItem.name }}</div>
          <div class="xa-cell">
            <div class="xa-txt-yellow xa-flex">￥{{ mItem.price }}</div>
            <div>x{{ mItem.count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="amount-info xa-bg-white xa-mgb-8 xa-cell__box"
      v-if="amountInfo"
    >
      <div class="xa-txt-15">支付费用</div>
      <div class="xa-cell">
        <div class="xa-flex">物料费</div>
        <div>￥{{ amountInfo.materialCost }}</div>
      </div>
      <div class="xa-cell">
        <div class="xa-flex">工时费</div>
        <div>￥{{ amountInfo.hourlyWage }}</div>
      </div>
      <div class="xa-txt-13 xa-txt-secondary">
        若客户需要维修服务，可向客户线下收取工时费
      </div>
    </div>
    <div
      class="pay-info xa-bg-white xa-mgb-8 xa-cell__box xa-txt-13"
      v-if="payInfo"
    >
      <div class="xa-cell">
        <div class="xa-txt-secondary">支付方式：</div>
        <div>{{ payInfo.payType === 'ONLINE' ? '在线支付' : '线下支付' }}</div>
      </div>
      <div class="xa-cell">
        <div class="xa-txt-secondary">支付状态：</div>
        <div>{{ payInfo.payStatus ? '已确认' : '待确认' }}</div>
      </div>
      <div class="xa-cell" v-if="payInfo.payStatus">
        <div class="xa-txt-secondary">支付状态：</div>
        <div>{{ payInfo.payTime }}</div>
      </div>
    </div>
    <EnsureFootBtn
      v-if="payInfo && !payInfo.payStatus"
      buttonText="确认客户已支付"
      @submit="onSubmit"
      :disabled="false"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import { getClientPaymentDetail, authPay } from '@/apis/authas'
export default {
  name: 'Cost-Bill',
  mixins: [basePage],
  config: {
    title: '支付费用',
    canRefresh: false
  },
  components: {
    EnsureFootBtn
  },
  data() {
    return {
      isLoading: true,
      query: null,
      notice: '', // 小程序页面头部黄色提醒内容
      baseInfo: null, // 基本信息
      materialList: [], // 物料信息
      amountInfo: null, // 费用信息  {materialCost:物料费用,hourlyWage:工时费}
      payInfo: null // 支付信息
    }
  },
  methods: {
    initMaterialList(list) {
      return list.map(item => {
        return {
          image: item.logo_url,
          ...item
        }
      })
    },
    async onSubmit() {
      await this.$_submitDataWidthUi(
        () =>
          this.$_request(
            authPay({
              ...this.query,
              offer_guid: this.baseInfo.offer_guid
            })
          ),
        '客户是否已付费？',
        '确认',
        true
      )
      this.initView()
    },
    async initView() {
      try {
        const {
          notice,
          baseInfo,
          materialList,
          amountInfo,
          payInfo
        } = await this.$_fetchDataWidthUi(
          getClientPaymentDetail({
            ...this.query
          })
        )
        this.amountInfo = amountInfo.isDisplay ? amountInfo : null
        this.baseInfo = baseInfo
        this.payInfo = payInfo
        this.materialList = materialList
        this.notice = notice
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.query = this.$route.query
    this.initView()
  }
}
</script>
<style lang="scss" scoped>
.material-info {
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: contain;
  }
}
.material-info-item {
  position: relative;
  padding: 10px 10px 10px 75px;
  line-height: 1.5;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 75px;
    right: 0;
    height: 1px;
    background-color: #e5e5e5;
    transform: scaleY(0.5);
    z-index: 1;
  }
}
</style>
