//  检查[获取]能授权售后的设备信息
export function checkCanAuthAs(data) {
  return {
    url: '/app/member/protection/service/home/check_can_auth_as',
    data
  }
}
//
export function getLastLossMaterial(data) {
  return {
    method: 'get',
    url: '/app/test/get/last/loss/material',
    data
  }
}
// 提交诊断接口
export function commitForAs(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/commit_for_as',
    data
  }
}

// 用户支付详情页面接口
export function getClientPaymentDetail(data) {
  return {
    method: 'post',
    url: '/app/member/protection/service/home/client_get_pay_order_info',
    data
  }
}

// 用户支付详情页面接口
export function authPay(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/auth/pay',
    data
  }
}

/*
 * 终端用户获取定责信息列表
 * 使用场景：小程序客户端在进入 检测报告/费用 --> 检测详情 --> 定责分析 处呈现该列表数据
 */
export function getClientGetLiabilityList(data) {
  return {
    method: 'get',
    url: '/app/member/protection/service/home/client_get_liability_list',
    data
  }
}

/**
 * 终端用户提交网点反馈页面
 * @param {*} data
 */
export function networkFeedback(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/home/fault_supply',
    data
  }
}
/**
 * 获取报修设备信息
 * @param {*} data
 */
export function getRepairEquipmentInfo(data) {
  return {
    method: 'get',
    url: '/app/operation/protection/service/loss/get_repair_equipment_info',
    data
  }
}
/**
 * 确认报修设备接口
 * @param {*} data
 */
export function ensureSn(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/ensure_sn',
    data
  }
}

/**
 * 录入好件信息列表页
 */
export function getInputGoodMaterialInfoList(data) {
  return {
    method: 'get',
    url:
      '/app/operation/protection/service/auth/input_good_materials_info_list',
    data
  }
}

// 单个录入或删除好件接口
export function editSingleMaterial(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/auth/input_single_good_materials',
    data
  }
}

// 单个录入或删除好件接口
export function editBatchMaterial(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/auth/input_batch_good_materials',
    data
  }
}

// 提交好件（生成报价单）接口
export function submitInputGoodMaterial(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/auth/submit_input_good_materials',
    data
  }
}

export function checkAuthCode(data) {
  return {
    url: '/app/operation/protection/service/loss/afs_scan_code_info',
    data
  }
}

// 终端用户获取服务单检测详情信息
export function getDiagnosisDetail(data) {
  return {
    url: '/app/member/protection/service/home/diagnosisDetails',
    data
  }
}
